import { h, Component } from 'preact'
import PropTypes from 'prop-types';
import MapUnitsSimple from '../MapUnitsSimple'

export default class MapUnitsSimpleApp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      states: [],
      cities: [],
      units: [],
    }

    this.setData()
  }

  async setData() {
    const units = this.props.units
    this.addState(units)
    this.addCity(units)
    this.addUnit(units)
  }

  addState(units) {
    units.forEach(unit => {
      const hasState = this.state.states.find(state => state.title === unit.state)

      if (hasState || !this.hasMap(unit)) return

      this.setState((state) => ({
        states: [
          ...state.states,
          {
            title: unit.state.toUpperCase()
          }
        ]
      }))
    })
  }

  addCity(units) {
    units.forEach(unit => {
      const hasCity = this.state.cities.find(city =>
        city.title === unit.city &&
        city.state === unit.state.toUpperCase()
      )

      if (hasCity || !this.hasMap(unit)) return

      this.setState((state) => ({
        cities: [
          ...state.cities,
          {
            title: unit.city,
            state: unit.state.toUpperCase(),
          }
        ]
      }))
    })
  }

  addUnit(units) {
    units.forEach(unit => {
      const hasUnit = this.state.units.find(unitState =>
        unitState.title === (unit.display_name || unit.name) &&
        unitState.state === unit.state.toUpperCase() &&
        unitState.city === unit.city
      )

      if (hasUnit || !this.hasMap(unit)) return

      this.setState((state) => ({
        units: [
          ...state.units,
          {
            id: unit.id,
            title: unit.display_name || unit.name,
            state: unit.state.toUpperCase(),
            city: unit.city,
            map: unit.map,
            priority: unit.priority,
          }
        ]
      }))
    })
  }

  /**
   * Verifica se a unidade tem mapa
   * @param {Object} unit
   */
  hasMap(unit) {
    return unit.map
  }

  render() {
    return (
      <MapUnitsSimple
        title={this.props.title}
        buttonLabel={this.props.buttonLabel}
        states={this.state.states}
        cities={this.state.cities}
        units={this.props.units}
      />
    )
  }
}

MapUnitsSimpleApp.defaultProps = {
  units: []
}

MapUnitsSimpleApp.propTypes = {
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
}
