import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import CustomSelect from '../CustomSelect'

export default class Form extends Component {
  get disableStates() {
    return this.props.states.length === 0
  }

  get disableCities() {
    return this.props.cities.length === 0
  }

  get disableUnits() {
    return this.props.units.length === 0
  }

  render() {
    return (
      <form
        className="map-units__form"
        onSubmit={this.handleSubmit}
      >
        <header className="map-units__header">
          <h3 className="map-units__title">{this.props.title}</h3>
        </header>

        <div className="form-group">
          <label className="control-label">Estado</label>
          <CustomSelect
            classNames={{
              containerOuter: 'choices choices--fill'
            }}
            name="state"
            handleSelectChange={this.props.onStateChange}
            options={this.props.states}
            searchEnabled={false}
            value={this.props.state}
            placeholderValue="Escolha o estado"
          />
        </div>

        <div className="form-group">
          <label className="control-label">Cidade</label>
          <CustomSelect
            classNames={{
              containerOuter: 'choices choices--fill'
            }}
            name="city"
            handleSelectChange={this.props.onCityChange}
            options={this.props.cities}
            searchEnabled={false}
            disable={this.disableCities}
            value={this.props.city}
            placeholderValue="Escolha a cidade"
          />
        </div>

        <div className="form-group">
          <label className="control-label">Loja</label>
          <CustomSelect
            classNames={{
              containerOuter: 'choices choices--fill'
            }}
            name="unit"
            handleSelectChange={this.props.onUnitChange}
            options={this.props.units}
            searchEnabled={false}
            disable={this.disableUnits}
            value={this.props.unit}
            placeholderValue="Escolha a loja"
          />
        </div>

        <div className="form-group map-units__cta">
          <button
            className="btn button button--dark button--large w-100"
            type="submit"
            onClick={this.props.onSubmit}
            disabled={this.props.disableSubmit}
          >
            {this.props.buttonLabel}
          </button>
        </div>

      </form>
    )
  }
}

Form.defaultProps = {
  title: 'Preencha os filtros abaixo e encontre uma de nossas lojas mais próxima de você.',
  buttonLabel: 'Buscar',
  disableSubmit: true,
  states: [],
  cities: [],
  units: [],
  state: '',
  city: '',
  unit: '',
  onBrandChange() { },
  onStateChange() { },
  onCityChange() { },
  onUnitChange() { },
  onSubmit() { },
}

Form.propTypes = {
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  disableSubmit: PropTypes.bool,
  states: PropTypes.array,
  cities: PropTypes.array,
  units: PropTypes.array,
  state: PropTypes.string,
  city: PropTypes.string,
  unit: PropTypes.unit,
  onBrandChange: PropTypes.func,
  onStateChange: PropTypes.func,
  onCityChange: PropTypes.func,
  onUnitChange: PropTypes.func,
  onSubmit: PropTypes.func,
}
