import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import handleInputChange from '../../utils/handleInputChange'
import Form from './Form'
import MapIframe from './MapIframe'

export default class MapUnitsSimple extends Component {
  constructor(props) {
    super(props)

    this.state = {
      state: '',
      city: '',
      unit: '',
      map: '',
    }

    this.handleInputChange = handleInputChange.bind(this)
    this.handleStateChange = this.handleStateChange.bind(this)
    this.handleCityChange = this.handleCityChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  get states() {
    return this.props.states
      .map(state => ({
        label: state.title,
        value: state.title,
      }))
  }

  get cities() {
    return this.props.cities
      .filter(city =>
        city.state === this.state.state
      )
      .map(city => ({
        label: city.title,
        value: city.title,
      }))
  }

  get units() {
    return this.props.units
      .filter(unit =>
        unit.state === this.state.state &&
        unit.city === this.state.city
      )
      .map(unit => ({
        label: unit.title,
        value: unit.id,
      }))
  }

  get disableSubmit() {
    return !this.state.unit
  }

  get mapSrc() {
    if (this.state.map) {
      return this.state.map
    }

    if (this.props.units.length > 0) {
      return this.props.units[0].map
    }
  }

  handleStateChange(e) {
    const { name, value } = e.target
    this.setState({
      [name]: value,
      city: '',
      unit: ''
    })
  }

  handleCityChange(e) {
    const { name, value } = e.target
    this.setState({
      [name]: value,
      unit: ''
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    const unit = this.props.units.find(unit => unit.id == this.state.unit)
    if (unit) {
      this.setState({ map: unit.map })
    }
  }

  render() {
    return (
      <div className="map-units">
        <Form
          title={this.props.title}
          buttonLabel={this.props.buttonLabel}

          disableSubmit={this.disableSubmit}

          states={this.states}
          cities={this.cities}
          units={this.units}

          state={this.state.state}
          city={this.state.city}
          unit={this.state.unit}

          onStateChange={this.handleStateChange}
          onCityChange={this.handleCityChange}
          onUnitChange={this.handleInputChange}
          onSubmit={this.handleSubmit}
        />
        <MapIframe src={this.mapSrc} />
      </div>
    )
  }
}

MapUnitsSimple.defaultProps = {
  states: [],
  cities: [],
  units: [],
}

MapUnitsSimple.propTypes = {
  states: PropTypes.array,
  cities: PropTypes.array,
  units: PropTypes.array,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
}
