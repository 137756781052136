import { h } from 'preact';
import { render } from 'react';
import AccordionVehicleWithVersion from '../auto/components/SubscriptionDetailTabs/AccordionVehicleWithVersion';
import ConversionFormModal from '../auto/components/ConversionFormModal';
import CustomSelect from '../auto/components/CustomSelect';
import Faq from '../auto/components/Subscriptions/Faq';
import HeaderFilterSubscriptions from '../auto/components/Subscriptions/HeaderFilterSubscriptions';
import ListSubscriptionsApp from '../auto/components/Subscriptions/ListSubscriptionsApp';
import OtherSubscriptionOffersApp from '../auto/components/Subscriptions/OtherSubscriptionOffersApp';
import StaticConversionFormApp from '../auto/components/StaticConversionFormApp';
import VideoGallery from '../auto/components/Video/Gallery';
import WhatsAppBoxWithModal from '../auto/components/WhatsAppBoxWithModal';
import SubscriptionPlanChoiceForm from '../auto/components/SubscriptionPlanChoiceForm/SubscriptionPlanChoiceForm';
import ConversionFormApp from '../auto/components/SubscriptionFormConversion/ConversionFormApp';
import MapUnitsSimpleApp from '../auto/components/MapUnitsSimpleApp';
import SeeConditions from '../auto/components/SeeConditions';
import SubscriptionDetailTabs from '../auto/components/SubscriptionDetailTabs';

if (process.env.NODE_ENV == 'development') {
  require('preact/debug');
}

(function () {
  window.h = h;
  window.render = render;

  /**
   * Para ser usado quando se deseja sobrescrever conteúdo do container de rendeziração.
   * Seu uso é semelhante a função render
   */
  window.hydrate = (vnode, parent) => {
    return render(vnode, parent, parent.firstElementChild);
  };

  // Adicione aqui chaves:valor com os componentes que serão atribuídos ao escopo
  // global. Não passe eles pelo hyperscript (h) aqui pois a página que os usa
  // é quem deve compilá-lo com os pros.
  let components = {
    AccordionVehicleWithVersion,
    ConversionFormModal,
    CustomSelect,
    SubscriptionDetailTabs,
    Faq,
    HeaderFilterSubscriptions,
    ListSubscriptionsApp,
    OtherSubscriptionOffersApp,
    VideoGallery,
    MapUnitsSimpleApp,
    WhatsAppBoxWithModal,
    SubscriptionPlanChoiceForm,
    ConversionFormApp,
    SeeConditions,
    StaticConversionFormApp,
  };

  Object.keys(components).forEach((c) => (window[c] = components[c]));
})();
